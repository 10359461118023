@import url("https://fonts.cdnfonts.com/css/vag-rounded-next");

//@import url('http://fonts.cdnfonts.com/css/aller');

body {
    // background: linear-gradient( 45.4deg,  rgba(33,150,243,1) 1.8%, rgb(22 233 255) 97.1% );
    font-family: "VAG Rounded Next", sans-serif;
    //font-family: 'Aller', sans-serif;
    font-size: 15px;
    line-height: 24px;
    font-weight: 400;
}

.container-fluid {
    max-width: 1140px;
}

.dropdown-menu {
    padding: 0;
    min-width: 200px;
    margin: 0;
    border-radius: 0;
}

.dropdown-item {
    font-size: 15px;
    padding: 5px 10px;
    outline: none;
}

.async-block {
    position: relative;
    min-height: 100px;
}

.dropdown-toggle::after {
    content: "\f107";
    font: normal normal normal 14px/1 FontAwesome;
    border: none;
    vertical-align: initial;
}

.with-overlay {
    position: relative;
}

.with-overlay:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    background: linear-gradient(
                    to bottom,
                    rgba(0, 0, 0, 0) 0%,
                    rgba(0, 0, 0, 0.009) 11.7%,
                    rgba(0, 0, 0, 0.034) 22.1%,
                    rgba(0, 0, 0, 0.072) 31.2%,
                    rgba(0, 0, 0, 0.123) 39.4%,
                    rgba(0, 0, 0, 0.182) 46.6%,
                    rgba(0, 0, 0, 0.249) 53.1%,
                    rgba(0, 0, 0, 0.32) 58.9%,
                    rgba(0, 0, 0, 0.394) 64.3%,
                    rgba(0, 0, 0, 0.468) 69.3%,
                    rgba(0, 0, 0, 0.54) 74.1%,
                    rgba(0, 0, 0, 0.607) 78.8%,
                    rgba(0, 0, 0, 0.668) 83.6%,
                    rgba(0, 0, 0, 0.721) 88.7%,
                    rgba(0, 0, 0, 0.762) 94.1%,
                    rgba(0, 0, 0, 0.79) 100%
    );
}

.breadcrumb {
    margin-bottom: 0;
}

.btn {
    border-radius: 0;
}

// end of generic css

#scrollUp {
    right: 20px;
    bottom: 20px;
    cursor: pointer;
    display: none;
    z-index: 999;
    position: fixed;
    background: #f57979;
    border-color: #f57979;
}

#scrollUp.visible {
    display: block;
}

.single-block {
    padding-top: 40px;
    padding-bottom: 40px;
    position: relative;
}

.single-block img {
    max-width: 100% !important;
}

// .result-column .table-responsive table{
//     border: 1px solid #ddd;
// }

// .result-column .table-responsive table tr:nth-child(odd){
//     background: #eee
// }

// .result-column table th, .result-column table td{
//     border: 1px solid #ddd;
//     border-style: solid !important;
//     border-width: 1px !important;
//     border-color: #ddd !important;
//     padding: 2px 7px;
//     text-align: center;
// }

// .bg-light-grey{
//     background: #eee;
// }

// fixed image
.fixed-image {
    background: #eee;
    overflow: hidden;
    display: flex;
}

.fixed-image img {
    width: 100%;
    height: 100%;
}

.fixed-image.cover img {
    object-fit: cover;
}

.fixed-image.contain img {
    object-fit: contain;
}

// background image
.background-image {
    background-position: center center;
    background-repeat: no-repeat;
    background-color: #eee;
}

.background-image.contain {
    background-size: contain;
}

.background-image.cover {
    background-size: cover;
}

// iframe
iframe {
    max-width: 100%;
}

//ckeditor left right styled image
img.left,
img.right {
    padding: 0.25rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    max-width: 100%;
    height: auto;
}

img.left {
    margin: 0 15px 15px 0;
    float: left;
}

img.right {
    margin: 0 0 15px 15px;
    float: right;
}
